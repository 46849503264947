import { FetchResult, AuthTokenResponse } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { getStandardHeaders, performFetchWithLogging } from 'src/api/utils/helpers';

export async function createToken(username: string, password: string): Promise<FetchResult<AuthTokenResponse>> {
  const payload = JSON.stringify({ email: username, password });

  return performFetchWithLogging(`${config.hostUrl}/api/v1/login`, {
    method: 'POST',
    headers: getStandardHeaders(),
    credentials: 'include',
    body: payload,
  });
}
export async function getAccountInfo(jwtToken: string): Promise<any> {
  try {
    const response = await fetch(`${config.hostUrl}/api/v1/account`, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch account info:', error);
    return null;
  }
}

export async function refreshToken(refresh: string): Promise<FetchResult<AuthTokenResponse>> {
  const payload = JSON.stringify({ refresh });

  return performFetchWithLogging(`${config.hostUrl}/api/v1/refresh/`, {
    method: 'POST',
    headers: getStandardHeaders(),
    credentials: 'include',
    body: payload,
  });
}

export async function verifyToken(token: string): Promise<FetchResult<any>> {
  const payload = JSON.stringify({ token });

  return performFetchWithLogging(`${config.hostUrl}/api/v1/verify/`, {
    method: 'POST',
    headers: getStandardHeaders(),
    credentials: 'include',
    body: payload,
  });
}

export async function serviceToken(accessToken: string): Promise<FetchResult<string>> {
  return performFetchWithLogging(
    `${config.hostUrl}/api/v1/authentication/service_token?scope=EDIT_ASSET`,
    {
      method: 'GET',
      headers: { Authorization: accessToken },
      credentials: 'include',
    },
    'text',
  );
}

export function activateAccount(code: string, accessToken: string): Promise<FetchResult<any>> {
  return performFetchWithLogging(`${config.hostUrl}/api/v1/account/activate/${code}`, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}
