import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useAuthUser } from 'react-auth-kit';

import { getThumbnailUrl } from 'src/utils/previews';
import { useAccessToken } from 'src/hooks/useAccessToken';
import { getAssetMediaUrl } from 'src/api/functions/assetsApi';

import * as ISOLocales from 'iso-locales';

import { Player, Video, DefaultUi, DefaultControls, Captions } from '@vime/react';

// Default theme.
import '@vime/core/themes/default.css';
import { Loader } from 'src/components';

import styles from './Modal.module.scss';

type PreviewParams = {
  assetType: string;
  assetId: string;
  conversions: any;
  dimensions: [width?: number, height?: number];
  getURL: (url: string) => void;
};

type TrackProps = {
  src: string;
  kind: string;
  label: string;
  default?: boolean;
  srcLang: string;
};

const videoKeys = [
  '240p-video',
  '240p-wm-video',
  '320p-video',
  '320p-wm-video',
  '360p-video',
  '360p-wm-video',
  '480p-video',
  '480p-wm-video',
  '720p-video',
  '720p-wm-video',
  '1080p-video',
  '1080p-wm-video',
];

const ModalPreview: React.FC<PreviewParams> = ({ assetType, assetId, conversions, dimensions, getURL }) => {
  const [datatype] = assetType.split('/') as ['image' | 'video' | 'video' | 'audio', string];

  const accessToken = useAccessToken();

  const auth = useAuthUser();

  const [mediaSrc, setMediaSrc] = useState<string>('');
  const [tracks, setTracks] = useState<TrackProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getURL(mediaThumbnail);
    loadMediaData();
  }, [assetId, assetType, conversions, accessToken]);

  useEffect(() => {
    setMediaSrc('');
    setTracks([]);
    loadMediaData();
  }, [assetId, assetType, conversions]);
  
  
  const mediaThumbnail = getThumbnailUrl({
    width: dimensions[0] ? Math.min(dimensions[0], 800) : 800,
    height: dimensions[1] ? Math.min(dimensions[1], 600) : 600,
    id: assetId,
    jwt: accessToken,
  });

  const possibleVideoKeys =
    datatype === 'video' ? videoKeys.filter((value) => Object.keys(conversions).includes(value)) : [];

  const getMediaUrl = async () => {
    setIsLoading(true);
    const url = await getAssetMediaUrl(
      assetId,
      possibleVideoKeys?.[0] ?? 'original',
      auth()!.accessId,
      'display',
      accessToken,
    );
    setMediaSrc(url);
  };

  const setDefaultTrack = (srcLang: string) => {
    const { language } = navigator;

    const languages = [language, language.split('-')[0]].map((lang) => lang.toLowerCase());

    return languages.includes(srcLang.toLowerCase());
  };

  const processSubtitle = async ({ subtitle, bucket, key, ...rest }: any) => {
    const [, langCode] = subtitle.split('_');
    const locale = ISOLocales.findByTag(langCode);

    const src = await getAssetMediaUrl(assetId, subtitle, auth()!.accessId, 'download', accessToken);

    return {
      kind: 'subtitles',
      src,
      srcLang: langCode,
      label: `${locale?.language_local ?? locale?.language} ${locale?.region ? `(${locale?.region})` : ''}`,
      default: setDefaultTrack(langCode),
    };
  };

  const getMediaTracks = async () => {
    const subtitles = Object.entries(conversions)
      .filter(([key]) => key.includes('subtitles'))
      .map(([subtitle, value]: [string, any]) => ({ ...value, subtitle }));

    const trackSources = await Promise.all(subtitles.map((subtitle) => processSubtitle(subtitle)));

    setTracks(trackSources);
  };

  const loadMediaData = async () => {
    if (datatype === 'image') {
      await getMediaUrl();
    } else {
      await Promise.all([getMediaUrl(), getMediaTracks()]);
    }
    setIsLoading(false);
  };
  
  
  switch (datatype) {
    case 'video':
    case 'audio':
      return isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
          <h3>Loading media...</h3>
        </div>
      ) : (
        <section style={{ width: '100%' }}>
          <Player key={mediaSrc}>
            <Video poster={mediaThumbnail} crossOrigin="anonymous">
              <source src={mediaSrc} />
              {tracks.map((track) => (
                <track key={track.srcLang} {...track} {...(tracks.length === 1 && { default: true })} />
              ))}
            </Video>
            <DefaultUi noControls>
              <DefaultControls hideOnMouseLeave activeDuration={2000} />
            </DefaultUi>
          </Player>
        </section>
      );
      
    case 'image':
    default:

      return <>
        {
          isLoading && <div className={styles.loaderContainer} hidden={!isLoading}>
            <Loader />
            <h3>Loading media...</h3>
          </div>
        }
        <img
          alt="Preview"
          data-test="preview-image"
          src={mediaThumbnail}
          onLoad={() => setIsLoading(false)}
          hidden={isLoading}
        />
      </>;

  }
};

export { ModalPreview };
